const es = {
  auth: {
    login: "Entrar",
    loginLink: "/giris",
    reset: {
      button: "Crear nueva contraseña",
      button2: "Restablece tu contraseña",
      description:
        "Escriba el correo electrónico con el que se registró y le enviaremos un correo electrónico para restablecer su contraseña.",
      errors: {
        emailError:
          "Se ha producido un error al intentar enviarle un correo electrónico. Comuníquese con nosotros a través de iletisim@hortiturkey.com",
        empty: "No puede estar vacío.",
        expiredRequest:
          "Esta solicitud de restablecimiento ha caducado. Vuelva a la página Restablecer contraseña y envíe una nueva solicitud.",
        noMatch: "Las contraseñas no coinciden",
        noUserFound:
          "No se encontró ningún usuario con este correo electrónico.",
        social:
          "Parece que te has registrado con una cuenta social. No tienes ninguna contraseña para restablecer. Ve a la página de inicio de sesión e intenta usar tu cuenta de redes sociales.",
        unexpected:
          "Se ha producido un error inesperado. Comunícate con nosotros para solucionar este problema.",
      },
      label1: "Nueva contraseña",
      label2: "Confirma tu nueva contraseña",
      label3: "Correo electrónico",
      loginLink: "Entrar",
      loginLinkDescription: "¿Recuerdas tu contraseña?",
      placeholder1: "Ingresa la nueva contraseña",
      placeholder2: "Confirma tu nueva contraseña",
      placeholder3: "Introduce tu correo electrónico",
      success: {
        changed:
          "Ha cambiado correctamente su contraseña y se le dirige a la página de inicio de sesión.",
        emailSent:
          "Le hemos enviado un correo electrónico para cambiar su contraseña.",
      },
      title: "Restablecer contraseña",
      title2: "Solicitud para restablecer contraseña",
    },
    signup: "Regístrate",
    signupLink: "/kayit",
  },
  blog: {
    noSources: "No hay fuentes para este artículo.",
    productTitle: "Productos similares",
    sources: "Fuentes",
    summary: "Resumen",
  },
  bottomReactions: {
    vote: {
      question: "¿Esta opinión es valiosa para ti?",
      title: "Votar",
    },
  },
  breadcrumb: {
    product: "Productos",
  },
  chatbox: {
    header: {
      title: "Centro de soporte",
    },
    home: {
      averageTime: "Tiempo de respuesta promedio: ",
      averageTimeText: "Entre 1 y 2 minutos.",
      button: "Iniciar un nuevo Chat",
      description:
        "Estamos aquí para ayudarte. Por favor, inicia un chat nuevo o selecciona uno existente.",
      previousChats: "Chats anteriores",
      title: "¡Bienvenido!",
    },
    input: {
      placeholder: "Escribe aquí tu mensaje",
    },
    unauthenticated: {
      anonymous: "Continuar sin registro",
      description:
        "Regístrese gratis para recibir soporte rápido y continuo de nuestro equipo de soporte.",
      login: "Entrar",
      loginText: "¿Ya tienes una cuenta?",
      privacyText:
        "Nos importa tu privacidad. Puede leer la política de privacidad para obtener más información.",
      signup: "Regístrate",
      title: "Por favor, regístrese para recibir asistencia ahora.",
    },
    ctaMessage: {
      title1: "¿Tienes una pregunta?",
      title2: "Estamos aquí para ayudarte.",
      onlineText: "El soporte está en línea.",
      button: "Iniciar un nuevo chat",
    },
  },
  company: {
    buttons: {
      seeProducts: "Ver productos",
    },
    product: "Productos",
    subcategory: "Subcategorías",
  },
  companyPage: {
    about: {
      contactTitle: "Información de contacto",
    },
    cta: {
      button: "Obtenga soporte en vivo",
      message:
        "¿Tiene alguna pregunta? Póngase en contacto con el equipo de soporte ahora.",
    },
    header: {
      productsButton: "Ver todos los productos",
      productsNumber: "Productos",
      supportButton: "Obtener ayuda",
    },
    sidebar: {
      about: "Acerca de",
      news: "Noticias",
      products: "Productos",
    },
  },
  contactUs: {
    Address: "Dirección",
    Company: "Empresa",
    CompanyName: "Nombre de la Compañía",
    Contact: "Contacto",
    Email: "Correo electrónico",
    Founders: "Fundadores",
    Office: "Oficina",
    Phone: "Teléfono",
    TaxID: "Identificación fiscal",
  },
  dataTips: {
    close: "Cerrar",
    edit: "Editar",
    emoji: "Agregar emoji",
    image: "Subir imagen",
    send: "Enviar",
  },
  deleteConfirmationPopup: {
    closeButton: "Cerrar",
    deleteButton: "Confirmar",
    infoText: "¡La eliminación de contenido no se puede deshacer!",
    text: "¿Estás seguro de eliminar esto? (No se puede volver a hacer esta operación).",
    title: "Confirmar operación de eliminación",
  },
  footer: {
    copyrightText:
      "© Todos los derechos están reservados. hortiturkey | BM Dijital Lim. Şti.",
    footerLinks: [
      [
        {
          link: "/tarim-urunleri? category=4be0839c-0c62-48f2-94b2-8cc3844a1242",
          title: "Nutrición de plantas",
        },
        {
          link: "/tarim-urunleri? category=cc22ca79-e616-4b75-be8e-adc8fa58baf2",
          title: "Protección de plantas",
        },
        {
          link: "/tarim-urunleri? category=98ad225d-8e31-458a-89ab-da80db2d9339",
          title: "Semillas y plántulas",
        },
        {
          link: "/tarim-urunleri? category=37d3bbe6-839e-4aeb-9047-1e43b17b8119",
          title: "Sistemas de riego",
        },
        {
          link: "/tarim-urunleri? category=4224995e-4cb9-4178-88d7-c166b6f0e1d6",
          title: "Invernadero y equipos",
        },
      ],
      [
        {
          link: "/tarim-urunleri",
          title: "Listas de productos",
        },
        {
          link: "/tarim-sirketleri",
          title: "Lista de empresas",
        },
        {
          link: "/tarim-fuarlari-takvimi",
          title: "Calendario de eventos",
        },
      ],
      [
        {
          link: "/hortiturkey-nedir",
          title: "¿Qué es hortiturkey?",
        },
        {
          link: "/iletisim",
          title: "Contáctenos",
        },
        {
          link: "/kvkk-kapsaminda-aydinlatma-metni",
          title: "GDPR",
        },
        {
          link: "/cerez-politikasi",
          title: "Política de cookies",
        },
      ],
    ],
    footerTitles: {
      title1: "Categorías de productos",
      title2: "Enlaces rápidos",
      title3: "Empresa",
    },
    miniCtaBox: {
      buttonTitle: "Obtener ayuda",
      description: "¿Necesita una consulta sobre agricultura en Turquía?",
      heading: "Obtener ayuda",
      link: "/link",
    },
    slogan:
      "Encuentre nuevos productos y empresas agrícolas en segundos para hacer crecer su negocio.",
  },
  formInputs: {
    emailErrors: {
      empty: "Introduce un email",
      rule1: "Ingresaste una dirección de correo electrónico no válida.",
    },
    emailLabel: "Correo electrónico",
    emailPlaceholder: "Introduce tu correo electrónico",
    fnameErrors: {
      empty: "Se requiere el primer nombre.",
      rule1: "El nombre debe tener al menos dos caracteres.",
    },
    fnameLabel: "Nombre",
    fnamePlaceholder: "Introduzca su nombre",
    lnameErrors: {
      empty: "El apellido es obligatorio.",
      rule1: "El apellido debe tener al menos dos caracteres.",
    },
    lnameLabel: "Apellido",
    lnamePlaceholder: "Introduce tu apellido",
    passwordErrors: {
      empty: "Se requiere contraseña",
      rule1:
        "La contraseña debe tener al menos 6 caracteres sin espacios; debe contener al menos un número y una letra mayúscula.",
    },
    passwordLabel: "Contraseña",
    passwordPlaceholder: "Introducir contraseña",
    saveButton: "Guardar",
  },
  globalSearch: {
    noHistoryMessage: "¡Encuentra todo lo que necesitas en un solo lugar!",
    placeholder: "Busque empresas, productos, ferias comerciales y más...",
    results: "Resultados",
    searchHistory: "Búsquedas anteriores",
    types: {
      all: "Todo",
      company: "Compañías",
      post: "Publicaciones",
      product: "Productos",
      trade_show: "Ferias comerciales",
    },
  },
  homeINT: {
    featuredProducts: {
      title: "Productos Destacados",
    },
    featuredCompanies: {
      title: "Empresas destacadas",
    },
    footer: {
      title1: "Contacta",
      title2: "con empresas agrícolas",
      title3: "de confianza en tu idioma.",
      chatButton: "INICIAR CHAT EN VIVO",
      boxTitle: "HABLAMOS TU IDIOMA",
      boxDescription:
        "Nuestros agentes pueden hablar ruso, árabe, inglés, español, persa y mucho más.",
    },
    boxes: {
      companies: {
        button: "Ver empresas",
        description:
          "Encuentre empresas exclusivas para establecer nuevas asociaciones.",
        title: "Compañías",
      },
      events: {
        button: "VER EVENTOS",
        description:
          "Encuentre los mejores eventos que se adapten a sus necesidades y obtenga información.",
        title: "Eventos",
      },
      getHelp: {
        button: "Iniciar chat en vivo",
        description: "¿Necesita una consulta sobre agricultura en Turquía?",
        title: "Obtener ayuda",
      },
      products: {
        button: "Ver productos",
        description:
          "Descubra más de 1.000 productos agrícolas y filtre lo que necesite.",
        title: "Productos",
      },
    },
    hero: {
      callout1: "Más de 1000 productos",
      callout2: "Empresas exclusivas",
      callout3: "Soporte multilingüe",
      callout4: "Chat en vivo con el agente",
      paragraph1:
        "¿Está buscando nuevos productos o nuevas empresas para establecer negocios?",
      paragraph2:
        "Encuentre todo lo que necesita en un solo lugar para hacer crecer su negocio agrícola.",
      title1: "Buscar",
      title2: "los mejores productos agrícolas",
      title3: "en un solo lugar.",
      what: "¿Qué es",
    },
  },
  imageUploader: {
    button: "Subir imagen",
    description:
      "Puedes subir hasta 3 imágenes. Las imágenes deben tener menos de 5 MB y JPG o PNG.",
    dropHereText: "Deja tu imagen aquí.",
    errors: {
      fileTooLarge: "El archivo es demasiado grande. Máximo permitido: 5 MB.",
      fileTooLargeChat:
        "El archivo es demasiado grande. Máximo permitido: 5 MB.",
      fileTypeInvalid:
        "El tipo de archivo no es válido. Permitido: jpg, jpeg, png.",
      fileTypeInvalidChat:
        "El tipo de archivo no es válido. Permitido: jpg, jpeg, png, pdf, doc, docx, etc.",
      tooManyFiles: "Demasiados archivos. Puedes subir hasta 5 imágenes.",
      tooManyFilesChat: "Demasiados archivos. Puedes enviar uno por uno.",
    },
    title: "Arrastrar y soltar o hacer clic",
  },
  listingPages: {
    filterButton: "Filtrar por",
    filterContainerTitle: "Filtros",
    foundCompanies: "Empresas encontradas:",
    foundProducts: "Productos encontrados:",
    foundTradeShows: "Programas encontrados:",
    mobileFilterButtons: {
      company: "Ver empresas",
      product: "Ver productos",
      tradeShow: "Ver ferias comerciales",
    },
    noResultButton: "Borrar todos los filtros",
    noResultMessage: "No hay ningún resultado para tu búsqueda.",
    pageHero: {
      about: {
        description:
          "¡Encuentre cientos de productos agrícolas de Turquía y haga crecer su negocio rápidamente!",
        title: "¿Qué es hortiturkey?",
        type: "hortiturkey",
      },
      company: {
        description:
          "Encuentre la empresa que busca en función del producto que necesita.",
        title: "Empresas agrícolas",
        type: "Compañías",
      },
      contactUs: {
        description:
          "¿Hay algo que te gustaría compartir o sugerir? Póngase en contacto con nosotros ahora y veamos qué podemos hacer juntos.",
        title: "Contáctenos",
        type: "Contacto",
      },
      product: {
        description:
          "Encuentra el producto que necesitas de forma fácil y rápida. ¡Póngase en contacto con la empresa en un segundo y haga crecer su negocio rápidamente!",
        title: "Productos agrícolas de Turquía",
        type: "Productos",
      },
      tradeShow: {
        description:
          "Encuentre ferias comerciales agrícolas nacionales e internacionales y obtenga la información más reciente sobre ellas.",
        title: "Ferias agrícolas 2022",
        type: "Programas",
      },
    },
    searchPlaceHolders: {
      company: "Buscar empresas...",
      product: "Buscar productos...",
      tradeShow: "Buscar ferias comerciales...",
    },
    showMoreButton: "Mostrar más",
  },
  listings: {
    bottomReactions: {
      comments: "Comentarios",
      likes: "Me gusta",
    },
    company: {
      category: "Categorías",
      product: "Productos",
    },
    tags: {
      "ETK\u0130NL\u0130K": "Evento",
      FUAR: "FERIA COMERCIAL",
      REHBER: "Guía",
      SORU: "Pregunta",
      YAZI: "Publicar",
      new: "Nuevo",
      passed: "Aprovadas",
      "\u0130\u015E \u0130LANI": "LISTADO DE TRABAJO",
    },
  },
  login: {
    forgotPassword: "¿Has olvidado tu contraseña?",
    invalidError:
      "La contraseña o la dirección de correo electrónico son incorrectas.",
    loginButton: "Entrar",
    loginWithEmail: "Iniciar sesión con correo electrónico",
    message: "Ha iniciado sesión correctamente.",
    regularError:
      "Ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.",
    signup: "Crear nueva cuenta",
    title: "Bienvenido de nuevo, inicie sesión.",
  },
  loginPopup: {
    mainText:
      "Crea una membresía gratuita para encontrar todo lo que buscas sobre el sector agrícola turco en un solo lugar.",
    privacyLink: "Política de privacidad",
    privacyText:
      "Nos importa tu privacidad. Puede leer la política de privacidad para obtener más información.",
    topText: "Inicie sesión para continuar.",
  },
  navigation: {
    navigationSearchButton: "Buscar",
    navigationSearchPlaceholder: "Productos, empresas, eventos, noticias...",
    searchLink: "/search",
    sidebar: {
      menuItem1: "Obtener ayuda (en vivo)",
      menuItem2: "Productos",
      menuItem2Link: "/tarim-urunleri",
      menuItem3: "Compañías",
      menuItem3Link: "/tarim-sirketleri",
      menuItem5: "Calendario de eventos",
      menuItem5Link: "/tarim-fuarlari-takvimi",
      menuItem6: "¿Qué es hortiturkey?",
      menuItem6Link: "/hortiturkey-nedir",
      menuItem7: "Contáctenos",
      menuItem7Link: "/iletisim",
      menuItem8: "GDPR",
      menuItem8Link: "/kvkk-kapsaminda-aydinlatma-metni",
      menuItem9: "Política de cookies",
      menuItem9Link: "/cerez-politikasi",
      menuTitle1: "Enlaces rápidos",
      menuTitle2: "Descubrir",
      menuTitle3: "HORTITURKEY",
      profile: "Ir al perfil",
    },
  },
  onboarding: {
    form: {
      back: "Atrás",
      finish: "Finalizar",
      indicator: {
        first: "Contacto",
        second: "expectativas",
        third: "Intereses",
      },
      inputs: {
        city: {
          label: "Ciudad",
          placeholder: "Introduce tu ciudad",
        },
        country: {
          label: "País",
          placeholder: "Selecciona tu país",
        },
        expectations: {
          help: "Conseguir consultas sobre la importación de Turquía.",
          learn: "Conozca las últimas noticias en el negocio agrícola.",
          network: "Construya una nueva red empresarial.",
          product: "Descubra nuevos productos para importar.",
          productSupport: "Obtenga el apoyo de las empresas productoras.",
          title: "¿Cuáles son sus expectativas de nosotros?",
        },
        import: {
          no: "No lo sabemos.",
          title: "¿Importa productos de Turquía?",
          yes: "Sí, lo sé.",
        },
        importGoods: {
          chemical: "Productos químicos",
          fertilizer: "Fertilizantes",
          greenhouse: "Equipos de invernadero",
          irrigation: "Sistemas de riego",
          other: "Otro",
          seed: "Las Semillas",
          title: "¿Qué importas?",
        },
        interests: {
          biological: "#controlbiológico",
          biologicalDescription: "Productos biológicos para el control.",
          description: "Debe elegir al menos tres de ellos.",
          greenhouse: "#invernadero",
          greenhouseDescription: "Construcción y equipos.",
          irrigation: "#sistemasderiego",
          irrigationDescription: "Descubra nuevos productos para importar.",
          management: "#administración",
          managementDescription: "Gestión de empresas agrícolas.",
          plantNutrition: "#nutricionvegetal",
          plantNutritionDescription: "Todo sobre los fertilizantes.",
          plantProtection: "#plandeproteccion",
          plantProtectionDescription: "Pesticidas, herbicidas, etc.",
          seed: "#semilla",
          seedDescription: "Sistemas de goteo, rociadores, etc.",
          title: "¿Qué temas te interesan más?",
          world: "#noticiasdelmundo",
          worldDescription: "Últimas noticias de la industria agrícola.",
        },
        persona: {
          consultant: "Consultor",
          engineer: "Ingeniero agricola",
          farmer: "Granjero",
          hobbyist: "aficionado",
          professional: "Profesional del sector",
          student: "Estudiante",
          title: "¿Cuál te describe mejor?",
        },
      },
      next: "Siguiente",
      thankyouPage: {
        description: "Se le dirigirá a la página de inicio en un segundo...",
        title: "¡Ha finalizado satisfactoriamente su registro!",
      },
      title: "bienvenido al club. ¿Podemos conocerte?",
      toast: {
        first: {
          message:
            "Esta información nos ayuda a realizar la personalización regional para usted y a resolver sus solicitudes con mayor rapidez.",
          title: "¿Por qué necesita esta información?",
        },
        second: {
          message:
            "Si lo prefieres, usaremos tu número de teléfono para responder más rápidamente a tus solicitudes. Puede estar seguro de que no nos comunicaremos de ninguna otra manera.",
          title: "¿Por qué necesita esta información?",
        },
      },
    },
    hero: {
      description:
        "Para obtener la mayor eficiencia de la hortiturkey, necesitamos conocerlo mejor.",
      step1: "Vamos a conocerte más de cerca",
      step2: "Entendamos tus expectativas.",
      step3: "Aprendamos sobre los temas que le interesan.",
      title: "¡Bienvenido al club!",
    },
  },
  pageDescriptions: {
    home: "Cientos de productos agrícolas de Turquía, empresas agrícolas y feria agrícola internacional en hortiturkey.",
  },
  pageTitles: {
    "404Page": "404",
    about: "¿Qué es hortiturkey?",
    companyListing: "Empresas agrícolas",
    contactUs: "Contáctenos",
    home: "hortiturkey",
    login: "Entrar",
    onboarding: "Inicio",
    productListing: "Productos agrícolas de Turquía",
    settings: "Ajustes",
    signup: "Regístrate",
    tradeshowListing: "Ferias agrícolas 2022",
  },
  postComments: {
    editor: {
      closeButton: "Cerrar",
    },
    loadMore: "Cargar más",
    mainTitle: "Comentarios",
    noCommentCta: "¡Escribe el primer comentario!",
    noCommentTitle: "No hay comentarios.",
    reply: "Contestar",
    settingsMenu: {
      delete: "Eliminar",
      edit: "Editar",
      report: "Informe",
    },
    textarea: {
      error: "¡Deberías escribir al menos 5 caracteres!",
      fastReplies: [
        "Gracias",
        "¡Gran artículo! 👏",
        "Interesante. 🤔",
        "El artículo necesita más trabajo... 👎",
      ],
      placeholder: "Escribe tus comentarios...",
      sendButton: "Enviar",
    },
  },
  product: {
    hero: {
      liveChatInfo:
        "Puede chatear con nosotros en vivo en el horario comercial de Turquía en inglés, ruso, español. Si el agente de soporte no está disponible, deje su mensaje y nos pondremos en contacto con usted un día después de enviarlo.",
      ownerCompany: "Empresa",
      productButtons: {
        live: "Hablar con el agente",
        request: "Solicitar Información",
        originalLink: "Ver en el sitio web del propietario ↗",
      },
    },
    otherProducts: "Otros productos",
    requestPopup: {
      alreadyRequested: {
        contact_preference: "Preferencia de contacto",
        date: "Fecha",
        description:
          "Puede encontrar los detalles de su solicitud a continuación. Ya te hemos recordado que estás esperando que te respondan lo antes posible.",
        email: "Correo electrónico",
        mobile: "Móvil",
        request: "Solicitar",
        title: "Ya has enviado una solicitud.",
        waiting: "Esperando respuesta",
      },
      form: {
        button: "Enviar",
        contactDontAsk: "Guardar mis preferencias de contacto.",
        contactDontAskText:
          "Su preferencia de contacto se guardará para las próximas solicitudes. Siempre puedes cambiar tu información de contacto desde tu perfil.",
        contactEmail: "Correo electrónico",
        contactMobile: "Móvil",
        contactTitle: "¿Cuál es el mejor para contactarlo?",
        messagePlaceholder: "Bríndeles algunos detalles sobre su solicitud.",
        messageTitle: "Mensaje",
        title: "Complete el formulario para enviar su solicitud",
      },
      newRequest: {
        description:
          "Puede encontrar los detalles de su solicitud de producto. Siempre puedes cambiar tu información de contacto desde tu perfil.",
        new: "Nuevo",
        title: "Has enviado una solicitud correctamente.",
        waitingTime: "Tiempo de respuesta",
        waitingTimeText: "Entre 1 y 3 días hábiles",
      },
      successToast: "Se ha enviado tu solicitud",
    },
    reviews: {
      filter: {
        all: "Todo",
        leastLiked: "Menos gustado",
        mostLiked: "Más valorados",
        topVoted: "Mejor puntuación",
      },
      input: {
        placeholder: "Escribe tu opinión aquí.",
        ratingError: "Debe calificar entre 1 y 5 estrellas",
        ratingText: "Valoración",
      },
      inputDescription: "Ayude a los demás escribiendo una opinión ahora.",
      inputTitle: "Escribir una reseña",
      loadMoreButton: "Cargar más",
      miniCtaText: "Escribir una reseña",
      miniCtaTextFirst: "¡Escribe la primera reseña!",
      noReviewText: "No hay ninguna opinión.",
      reviewReplyItem: "Respuesta de la empresa",
      reviewSectionDescription: "Opiniones de los usuarios.",
      reviewText: "Reseñas",
    },
    tabs: {
      labelText: "Descargar etiqueta del producto (PDF)",
      labelWarning:
        "Utilice los productos fitosanitarios con cuidado. Asegúrese de leer la etiqueta y la información del producto antes de usarlo.",
      menu: {
        agents: "Agentes",
        description: "Descripción de Proyecto",
        instructions: "Instrucciones del producto",
        reviews: "Reseñas",
      },
    },
  },
  search: {
    searchBar: {
      cta: "Buscar",
      placeholders: {
        product: "Buscar productos...",
      },
    },
  },
  settings: {
    bookmarks: {
      noBookmarks: "No tienes marcadores.",
      noSearchResults: "No se han encontrado resultados",
      searchPlaceHolder: "Busca en tus marcadores.",
      title: "Tus marcadores",
      titles: {
        posts: "Publicaciones",
      },
    },
    ctaBox: {
      buttonText: "Obtenga soporte en vivo",
      message:
        "¿Necesitas ayuda? Póngase en contacto con nuestro equipo de soporte.",
    },
    header: {
      bioPlaceholder: "Háblenos de usted en breve.",
      newMember: "Nuevo miembro",
      producer: "El Productor",
    },
    profile: {
      experience: {
        companyLabel: "Empresa",
        companyPlaceholder: "Informe el nombre de empresa",
        miniBioLabel: "Mini biografía",
        miniBioLength: "Los personajes cuentan:",
        miniBioPlaceholder: "Por favor, introduzca su mini biografía.",
        title: "Información profesional",
        titleLabel: "Título",
        titlePlaceholder: "Por favor, introduzca su título",
      },
      messages: {
        miniBioError: "La mini biografía no puede tener más de 200 caracteres.",
        success: "Perfil actualizado con éxito",
      },
      personal: {
        emailLabel: "Correo electrónico",
        fnameLabel: "Nombre",
        lnameLabel: "Apellido",
        title: "Información personal",
      },
      saveButton: "Guardar cambios",
      social: {
        title: "Redes sociales y enlaces",
      },
    },
    requests: {
      actionsTitle: "Acciones",
      company: "Empresa",
      date: "Fecha",
      generalRequests: "Solicitudes generales",
      message: "Mensaje",
      new: "Nuevo",
      noRequests: "No tienes solicitudes.",
      product: "Producto",
      title: "Tus peticiones",
      type: "Tipo",
    },
    security: {
      accountDelete: {
        button: "Eliminar cuenta",
        confirmationDescription:
          "Escriba su «Nombre» y «Apellidos» con un espacio intermedio. A continuación, haga clic en el botón «Aprobar» para eliminar su cuenta permanentemente.",
        confirmationNote:
          "Tenga en cuenta que este proceso es irreversible y todas sus fechas se borrarán permanentemente, incluidas sus solicitudes, reseñas y mensajes.",
        inputLabel: "Aprobar",
        message:
          "Lamentamos mucho que se vaya. Si puede decirnos por qué nos deja, haremos todo lo posible para mejorar nuestro servicio.",
        radioTitle: "¿Cuál es el motivo de la partida?",
        title: "Eliminar cuenta",
      },
      messages: {
        accountDeleted: "Tu cuenta ha sido eliminada",
        matchError:
          "Asegúrese de haber introducido su nombre y apellidos con un espacio intermedio.",
      },
      password: {
        resetMessage:
          "Envíe su solicitud de restablecimiento de contraseña para recibir un correo electrónico de nuestra parte. Siga las instrucciones que le hemos enviado para restablecer su contraseña.",
        socialMessage:
          "Parece que estás usando el inicio de sesión social. Por lo tanto, no puede cambiar su contraseña.",
        title: "Cambiar contraseña",
      },
    },
    sidebarMenu: {
      bookmarks: "Marcadores",
      profile: "Perfil",
      requests: "Solicitudes",
      security: "Seguridad",
    },
  },
  signup: {
    alreadymember: "¿Ya eres miembro?",
    cookie: "Política de cookies",
    description:
      "Puede unirse a nosotros en un minuto utilizando sus cuentas sociales y disfrutar de un inicio de sesión seguro y sin contraseña.",
    email: "Regístrate con tu email",
    form: {
      consentText:
        "Me gustaría recibir mensajes electrónicos dentro del alcance de la Política de privacidad para estar informado sobre campañas importantes.",
      error1: "Este correo electrónico ya está en uso. Intente iniciar sesión.",
      error2: "Ha ocurrido un error. Por favor, inténtalo de nuevo más tarde.",
    },
    hero: {
      description:
        "¿Está buscando nuevos productos o nuevas empresas para establecer negocios? Encuentre todo lo que necesita en un solo lugar para hacer crecer su negocio agrícola.",
      joinus: "Únase",
      members: "Miles de miembros",
      title1: "Encuentra todo",
      title2: "Lo necesitas en un solo lugar.",
    },
    login: "Entrar",
    privacy: "Política de privacidad",
    privacyText:
      "Sus datos personales se procesan dentro del alcance de la Política de privacidad. Al presionar uno de los botones «Registrarse» o «Cuenta social», confirma que ha leído y aceptado la Política de privacidad y cookies.",
    signup: "Registrarse",
    title: "Registrarse",
    toast: {
      error: "Ocurrió un error",
      success: "Se ha registrado correctamente.",
    },
  },
  time: {
    now: "Ahora",
  },
  toast: {
    deleteComment: "No puedes eliminar comentarios que tengan respuestas.",
    error: "Ocurrió un error",
    success: "Éxito",
  },
  tradeshow: {
    cta: {
      button: "Calendario ferias 2022",
      message:
        "¿Buscas ferias agrícolas? ¡Consulta el calendario de ferias agrícolas!",
    },
    noExhibitors:
      "La lista de expositores no se publica o no hay ningún expositor que sea miembro de hortiturkey.",
    onlineRegistration: "Inscripción en línea",
    sidebar: {
      about: "Acerca de",
      exhibitors: "Expositores",
    },
    titles: {
      eventLocation: "Lugar del evento",
    },
  },
  utilities: {
    "404Page": {
      button: "Volver a la página de inicio",
      message:
        "La página que busca no existe o escribió una URL incorrecta. Comuníquese con nosotros si cree que esto es un error.",
    },
    authorBox: {
      editorInChief: "Editor en jefe",
      member: "Nuevo miembro",
    },
    errorMessages: {
      wentWrong: "Algo salió mal. Actualiza la página.",
    },
    personas: {
      consultant: "Consultor",
      engineer: "Ingeniero",
      farmer: "Granjero",
      hobbyist: "aficionado",
      professional: "Profesional",
      student: "Estudiante",
    },
    updatedAt: "Actualizado el",
  },
  // Event Page
  event: {
    title: "EVENTOS",
    button: {
      register: "Regístrate",
      registered: "Ya Registrada",
    },
    platforms: "¿Dónde mirar?",
    titles: {
      eventContent: "Contenido del Evento",
      speakers: "Oradoras",
    },
    footer: {
      poweredBy: "Desarrollado por hortiturkey",
    },
    share: {
      linkCopied: "Link Copiado al Portapapeles",
      title: "¡Comparte el Evento!",
      copy: "Copiar link",
    },
    status: {
      live: "VIVO",
      ended: "TRANSMISIÓN FINALIZADA",
    },
    chatbox: {
      joinText:
        "Puede unirse al chat para hacer preguntas a los oradores y conocer a otros asistentes.",
      joinButton: "Unirse al chat",
      emptyText: "Aún no hay mensajes.",
    },
    live: {
      startingText: "El evento comenzará en unos minutos.",
      startingTextJoin: "No olvides unirte al chat mientras esperas.",
      endText: "El evento ha terminado. Gracias por su participación.",
    },
  },
  // Featured TradeShow
  featuredTradeShow: {
    badge: "Feria Comercial Destacada",
    button1: "Aprende más",
    button2: "Regístrate Gratis",
  },
};

export default es;
