const en = {
  navigation: {
    navigationSearchPlaceholder: "Products, companies, events, news...",
    navigationSearchButton: "SEARCH",
    searchLink: "/search",
    sidebar: {
      menuTitle1: "QUICK LINKS",
      menuTitle2: "DISCOVER",
      menuTitle3: "HORTITURKEY",
      menuItem1: "Get Help (Live)",
      menuItem2: "Products",
      menuItem2Link: "/tarim-urunleri",
      menuItem3: "Companies",
      menuItem3Link: "/tarim-sirketleri",
      menuItem5: "Event Calendar",
      menuItem5Link: "/tarim-fuarlari-takvimi",
      menuItem6: "What is hortiturkey?",
      menuItem6Link: "/hortiturkey-nedir",
      menuItem7: "Contact Us",
      menuItem7Link: "/iletisim",
      menuItem8: "GDPR",
      menuItem8Link: "/kvkk-kapsaminda-aydinlatma-metni",
      menuItem9: "Cookie Policy",
      menuItem9Link: "/cerez-politikasi",
      profile: "Go to Profile",
    },
  },
  auth: {
    login: "Log in",
    signup: "Signup",
    loginLink: "/giris",
    signupLink: "/kayit",
    reset: {
      title: "Reset Password",
      title2: "Reset Password Request",
      description:
        "Please type your email that you registered with and we will be sending an email to reset your password.",
      placeholder1: "Enter a new password.",
      placeholder2: "Confirm your new password.",
      placeholder3: "Enter your email.",
      label1: "New Password",
      label2: "Confirm Your New Password",
      label3: "Email",
      button: "Create New Password",
      button2: "Reset Your Password",
      loginLinkDescription: "Remembered your paassword?",
      loginLink: "Login",
      errors: {
        empty: "It can't be empty.",
        noMatch: "The passwords don't match.",
        expiredRequest:
          "This reset request has expired. Please go back to Reset Password page and send new reqeust.",
        unexpected:
          "An unexpected error occured. Please reach out to use to fix this issue.",
        noUserFound: "No user found with this email.",
        emailError:
          "An error occured when we try to send an email to you. Please reach out to us via iletisim@hortiturkey.com",
        social:
          "Looks like you have registered with a social account. You don't have any password to reset. Please go to Login page and try to use your social account.",
      },
      success: {
        changed:
          "You have successfully changed your password and being directed to the login page.",
        emailSent: "We have sent you an email to change your password.",
      },
    },
  },
  footer: {
    copyrightText:
      "© All rights are reserved. hortiturkey | BM Dijital Lim. Şti.",
    slogan:
      "Find new agricultural products and companies in seconds to grow your business.",
    footerTitles: {
      title1: "Product Categories",
      title2: "Quick Links",
      title3: "Company",
    },
    miniCtaBox: {
      heading: "GET HELP",
      description: "Do you need consultation on agriculture in Turkey?",
      buttonTitle: "Get Support",
      link: "/link",
    },
    footerLinks: [
      [
        {
          title: "Plant Nutrition",
          link: "/tarim-urunleri?category=4be0839c-0c62-48f2-94b2-8cc3844a1242",
        },
        {
          title: "Plant Protection",
          link: "/tarim-urunleri?category=cc22ca79-e616-4b75-be8e-adc8fa58baf2",
        },
        {
          title: "Seed and Seedlings",
          link: "/tarim-urunleri?category=98ad225d-8e31-458a-89ab-da80db2d9339",
        },
        {
          title: "Irrigation Systems",
          link: "/tarim-urunleri?category=37d3bbe6-839e-4aeb-9047-1e43b17b8119",
        },
        {
          title: "Greenhouse and Eqiupments",
          link: "/tarim-urunleri?category=4224995e-4cb9-4178-88d7-c166b6f0e1d6",
        },
      ],
      [
        {
          title: "Product Lists",
          link: "/tarim-urunleri",
        },
        {
          title: "Company List",
          link: "/tarim-sirketleri",
        },
        {
          title: "Event Calender",
          link: "/tarim-fuarlari-takvimi",
        },
      ],
      [
        {
          title: "What is hortiturkey?",
          link: "/hortiturkey-nedir",
        },
        {
          title: "Contact Us",
          link: "/iletisim",
        },
        {
          title: "GDPR",
          link: "/kvkk-kapsaminda-aydinlatma-metni",
        },
        {
          title: "Cookie Policy",
          link: "/cerez-politikasi",
        },
      ],
    ],
  },
  blog: {
    summary: "Summary",
    productTitle: "Related Products",
    sources: "Sources",
    noSources: "There are no sources for this article.",
  },
  pageTitles: {
    home: "hortiturkey",
    settings: "Settings",
    signup: "Signup",
    login: "Login",
    onboarding: "Onboarding",
    about: "What is hortiturkey?",
    contactUs: "Contact Us",
    "404Page": "404",
    companyListing: "Agricultural Companies",
    productListing: "Agricultural Products from Turkey",
    tradeshowListing: "Agricultural Trade Shows 2022",
  },
  pageDescriptions: {
    home: "Hundreds of agricultural products from Turkey, agricultural companies, and international agricultural trade show in hortiturkey.",
  },
  // Onboarding Process - /kayit/tanisma
  onboarding: {
    hero: {
      title: "Welcome to the Club!",
      description:
        "In order to get the highest efficiency from hortiturkey, we need to get to know you better.",
      step1: "Let's Get to Know You Closer",
      step2: "Let's Understand Your Expectations.",
      step3: "Let's Learn About the Topics that Interest You.",
    },
    form: {
      title: "welcome to the club. Can we get to know you?",
      indicator: {
        first: "Contact",
        second: "Expectations",
        third: "Interests",
      },
      toast: {
        first: {
          title: "Why do you need this information?",
          message:
            "This information helps us both to do regional customization for you and to resolve your requests faster.",
        },
        second: {
          title: "Why do you need this information?",
          message:
            "If you prefer, we will use your phone number for faster response to your requests. You can rest assured that we will not communicate in any other way.",
        },
      },
      next: "Next",
      back: "Back",
      finish: "Finish",
      thankyouPage: {
        title: "You have succuessfully finished your registration!",
        description: "You are being directed to the homepage in a second...",
      },
      inputs: {
        country: { label: "Country", placeholder: "Select your country" },
        city: { label: "City", placeholder: "Enter your city" },
        persona: {
          title: "Which one describes you best?",
          farmer: "Farmer",
          engineer: "Agricultural Engineer",
          professional: "Sector Professional",
          consultant: "Consultant",
          student: "Student",
          hobbyist: "Hobbyist",
        },
        import: {
          title: "Do you import prodcuts from Turkey?",
          yes: "Yes, I do.",
          no: "We don't.",
        },
        importGoods: {
          title: "What do you import?",
          chemical: "Chemicals",
          fertilizer: "Fertilezers",
          seed: "Seeds",
          greenhouse: "Greenhouse Equipment",
          irrigation: "Irrigation Systems",
          other: "Other",
        },
        expectations: {
          title: "What are your expectations from us?",
          learn: "Learn latest the news in agricultural business.",
          product: "Discover new products to import.",
          productSupport: "Get support from producer companies.",
          network: "Build new business network.",
          help: "Getting consultation on importing from Turkey.",
        },
        interests: {
          title: "Which topics do you interests most?",
          description: "You must pick at least three of them.",
          plantProtection: "#plantprotection",
          plantProtectionDescription: "Pesticides, herbicides etc.",
          plantNutrition: "#plantnutrition",
          plantNutritionDescription: "Everything about fertilizers.",
          greenhouse: "#greenhouse",
          greenhouseDescription: "Construction adn equipment.",
          seed: "#seed",
          seedDescription: "Drip, springler systems etc.",
          irrigation: "#irrigationsystems",
          irrigationDescription: "Discover new products to import.",
          biological: "#biologicalcontrol",
          biologicalDescription: "Bio products for control.",
          management: "#management",
          managementDescription: "Agricultural business management.",
          world: "#newsfromworld",
          worldDescription: "Latest news from the agricultrure industry.",
        },
      },
    },
  },
  // Signup Process - /kayit/
  signup: {
    title: "Register",
    description:
      "You can join us in a minute by using your social accounts and enjoy passwordless and secure login.",
    email: "Sign up with Email",
    signup: "Sign up",
    alreadymember: "You are already a member?",
    login: "Login",
    privacyText:
      "Your personal data is processed within the scope of the Privacy Policy. By pressing one of the 'Signup' or 'Social Account' buttons, you confirm that you have read and accepted the Privacy & Cookie Policy.",
    privacy: "Privacy Policy",
    cookie: "Cookie Policy",
    hero: {
      title1: "Find Everything",
      title2: "You Need in One Place.",
      description:
        "Are you looking for new products or new companies to establish business? Find everything you need in one place to grow your agriculture business.",
      joinus: "JOIN US",
      members: "Thousands of Members",
    },
    form: {
      consentText:
        "I would like to receive electronic messages within the scope of the Privacy Policy to be informed about important campaigns.",
      error1: "This email is already in use. Please try to login.",
      error2: "An error occurred. Please try again later.",
    },
    toast: {
      success: "You have successfully registered.",
      error: "An error occured!",
    },
  },
  // General Form Inputs
  formInputs: {
    fnameLabel: "First Name",
    fnamePlaceholder: "Enter your name",
    fnameErrors: {
      empty: "First name is required.",
      rule1: "First name must have at least two characters.",
    },
    lnameLabel: "Last Name",
    lnamePlaceholder: "Enter your surname",
    lnameErrors: {
      empty: "Last name is required.",
      rule1: "Last name must have at least two characters.",
    },
    emailLabel: "E-mail",
    emailPlaceholder: "Enter your email",
    emailErrors: {
      empty: "Email is required.",
      rule1: "You entered an invalid email address.",
    },
    passwordLabel: "Password",
    passwordPlaceholder: "Enter a password",
    passwordErrors: {
      empty: "Password is required.",
      rule1:
        "Password must be at least 6 characters without spaces; must contain at least one number and uppercase letter.",
    },
    saveButton: "Save",
  },
  // Login Process - /login
  login: {
    title: "Welcome back, please login.",
    loginWithEmail: "Login with Email",
    loginButton: "Login",
    forgotPassword: "Forgot your password?",
    signup: "Create a new account",
    invalidError: "Your password or email address is incorrect.",
    regularError: "An error occurred. Please try again later.",
    message: "You have successfully logged in.",
  },
  // Post comments - /p/slug
  postComments: {
    mainTitle: "Comment(s)",
    noCommentTitle: "There is no comment.",
    noCommentCta: "Write the first comment!",
    reply: "Reply",
    loadMore: "Load More",
    textarea: {
      placeholder: "Write your comment.",
      sendButton: "SEND",
      error: "You should write at least 5 characters!",
      fastReplies: [
        "Thanks 🙂",
        "Great article! 👏",
        "Interesting! 🤔",
        "Article needs more work... 👎",
      ],
    },
    editor: {
      closeButton: "Close",
    },
    settingsMenu: {
      edit: "Edit",
      delete: "Delete",
      report: "Report",
    },
  },
  // Toast
  toast: {
    error: "An error occured!",
    success: "Success!",
    deleteComment: "You can't delete comments that have replies.",
  },
  // Delete confirmation popup - Global
  deleteConfirmationPopup: {
    title: "Confirm Delete Operation",
    text: "Are you sure deleting this? (You can't redo this operation.)",
    infoText: "Deleting content is can not be undone!",
    deleteButton: "Confirm",
    closeButton: "Close",
  },
  // Login popup - Global
  loginPopup: {
    topText: "Please login to continue.",
    mainText:
      "Create a free membership to find everything you are looking for about Turkish agriculture sector in one place.",
    privacyText:
      "We care about your privacy. You can read the privacy policy for details.",
    privacyLink: "Privacy Policy",
  },
  // Image Uploader - Global
  imageUploader: {
    dropHereText: "Drop your image here.",
    title: "Drag & Drop or Click",
    description:
      "You can upload up to 3 images. Images should be less than 5MB and JPG or PNG.",
    button: "Upload Image",
    errors: {
      tooManyFiles: "Too many files. You can upload up to 5 images.",
      fileTooLarge: "File is too large. Max allowed: 5MB.",
      fileTypeInvalid: "File type is invalid. Allowed: jpg, jpeg, png.",
      tooManyFilesChat: "Too many files. You can send one by one.",
      fileTooLargeChat: "File is too large. Max allowed: 5MB.",
      fileTypeInvalidChat:
        "File type is invalid. Allowed: jpg, jpeg, png, pdf, doc, docx etc.",
    },
  },
  // Product Page - /urunler/slug
  product: {
    requestPopup: {
      successToast: "Your request has been sent.",
      form: {
        title: "Fill the form to sent your request",
        button: "Send",
        contactMobile: "Mobile",
        contactEmail: "Email",
        contactTitle: "Which one is the best to contact you?",
        contactDontAsk: "Save my contact preference.",
        contactDontAskText:
          "You contact preference will be saved for next requests. You can always change your contact information from your profile.",
        messageTitle: "Message",
        messagePlaceholder: "Give them some detail about your request.",
      },
      alreadyRequested: {
        request: "Request",
        title: "You have already sent a request.",
        description:
          "You can find the details of your request below. We have already reminded your are waiting to make them get back to you as soon as possible.",
        waiting: "Waiting for Response",
        date: "Date",
        email: "Email",
        mobile: "Mobile",
        contact_preference: "Contact Preference",
      },
      newRequest: {
        title: "You have successfully sent a request.",
        description:
          "You can find the details of your product request. You can always change your contact information from your profile.",
        waitingTime: "Reponse Time",
        waitingTimeText: "Between 1-3 Business Days",
        new: "New",
      },
    },
    hero: {
      liveChatInfo:
        "You can chat with us live in business hours of Turkey in English, Russian, Spannish. If support agent is not available please leave your message and we will be get back to you day after you sent it.",
      productButtons: {
        live: "Talk to Agent",
        request: "Request Information",
        originalLink: "See on Owner's Website ↗",
      },
      ownerCompany: "Company",
    },
    otherProducts: "Other Products",
    tabs: {
      menu: {
        description: "Product Description",
        instructions: "Product Instructions",
        reviews: "Reviews",
        agents: "Agents",
      },
      labelText: "Download Product's Label(PDF)",
      labelWarning:
        "Use plant protection products with care. Be sure to read the label and product information before use.",
    },
    reviews: {
      reviewText: "Review(s)",
      reviewSectionDescription: "Reviews from users.",
      noReviewText: "There is no review.",
      miniCtaText: "Write a review.",
      miniCtaTextFirst: "Write the first review!",
      inputTitle: "Write a Review",
      inputDescription: "Help others by writing a review now.",
      filter: {
        all: "All",
        mostLiked: "Most Liked",
        leastLiked: "Least Liked",
        topVoted: "Top Voted",
      },
      input: {
        placeholder: "Write your review here.",
        ratingText: "Rating",
        ratingError: "You must rate between 1-5 stars",
      },
      loadMoreButton: "Load More",
      reviewReplyItem: "Company's Answer",
    },
  },
  breadcrumb: {
    product: "Products",
  },
  dataTips: {
    send: "Send",
    close: "Close",
    edit: "Edit",
    image: "Upload Image",
    emoji: "Add an Emoji",
  },
  bottomReactions: {
    vote: {
      title: "Vote",
      question: "Is this review valuable for you?",
    },
  },
  company: {
    product: "Product(s)",
    subcategory: "Subcategories",
    buttons: {
      seeProducts: "See Products",
    },
  },
  time: {
    now: "Now",
  },
  // Chatbox
  chatbox: {
    header: {
      title: "Support Center",
    },
    input: {
      placeholder: "Type your message here...",
    },
    home: {
      title: "Welcome",
      description:
        "We are here to help you. Please start a new chat or select an existing one.",
      button: "Start a New Chat",
      averageTime: "Average Response Time: ",
      averageTimeText: "Between 1-2 Minutes.",
      previousChats: "Previous Chats",
    },
    unauthenticated: {
      title: "Please signup to get support now.",
      description:
        "Signup free to get quick and continuous support from our support team.",
      signup: "Signup",
      login: "Login",
      anonymous: "Continue without Registration",
      loginText: "Already have an account?",
      privacyText:
        "We care about your privacy. You can read the privacy policy for details.",
    },
    ctaMessage: {
      title1: "Do you have a question?",
      title2: "We are here to help you.",
      onlineText: "Support is online.",
      button: "Start a New Chat",
    },
  },
  // Listings
  listings: {
    tags: {
      new: "NEW",
      SORU: "QUESTION",
      FUAR: "TRADE SHOW",
      ETKİNLİK: "EVENT",
      "İŞ İLANI": "JOB LISTING",
      REHBER: "GUIDE",
      passed: "Passed",
      YAZI: "POST",
    },
    bottomReactions: {
      comments: "Comments",
      likes: "Likes",
    },
    company: {
      product: "Product(s)",
      category: "Categories",
    },
  },
  // Company Page - /c/slug
  companyPage: {
    sidebar: {
      products: "Products",
      about: "About",
      news: "News",
    },
    header: {
      productsNumber: "Products",
      productsButton: "See All Products",
      supportButton: "Get Support",
    },
    cta: {
      message:
        "Do you have a question? Please reach out to the support team now.",
      button: "Get Live Support",
    },
    about: {
      contactTitle: "Contact Information",
    },
  },
  // Search
  search: {
    searchBar: {
      placeholders: {
        product: "Search Products",
      },
      cta: "SEARCH",
    },
  },
  // Tradeshow
  tradeshow: {
    onlineRegistration: "Online Registration",
    titles: {
      eventLocation: "Event Location",
    },
    noExhibitors:
      "Exhibitor list is not published or there is no exhibitor who is member of hortiturkey.",
    cta: {
      button: "Tradeshows Calendar 2022",
      message:
        "Are you looking for agricultural tradeshows? Check out agricultural tradeshows calendar!",
    },
    sidebar: {
      about: "About",
      exhibitors: "Exhibitors",
    },
  },
  // Utilities
  utilities: {
    authorBox: {
      editorInChief: "Editor-in-Chief",
      member: "New Member",
    },
    "404Page": {
      button: "Back to home",
      message:
        "The page you are looking for does not exist or you typed the wrong URL. Please reach out to us if you think this is a mistake.",
    },
    personas: {
      farmer: "Farmer",
      engineer: "Engineer",
      consultant: "Consultant",
      professional: "Professional",
      student: "Student",
      hobbyist: "Hobbyist",
    },
    errorMessages: {
      wentWrong: "Something went wrong. Please refresh the page.",
    },
    updatedAt: "Updated at: ",
  },
  // Settings Page
  settings: {
    header: {
      bioPlaceholder: "Tell us about yourself shortly.",
      newMember: "New Member",
      producer: " Producer",
    },
    ctaBox: {
      buttonText: "Get Live Support",
      message: "Do you need help? Please reach out to our support team.",
    },
    sidebarMenu: {
      profile: "Profile",
      security: "Security",
      requests: "Requests",
      bookmarks: "Bookmarks",
    },
    profile: {
      messages: {
        success: "Profile updated successfully.",
        miniBioError: "Mini bio can not be longer than 200 characters.",
      },
      personal: {
        title: "Personal Information",
        emailLabel: "Email",
        fnameLabel: "First Name",
        lnameLabel: "Last Name",
      },
      experience: {
        title: "Professional Information",
        titlePlaceholder: "Please enter your title.",
        titleLabel: "Title",
        companyLabel: "Company",
        companyPlaceholder: "Please enter your company name.",
        miniBioPlaceholder: "Please enter your mini bio.",
        miniBioLabel: "Mini Bio",
        miniBioLength: "Characters count:",
      },
      social: {
        title: "Social Media and Links",
      },

      saveButton: "Save Changes",
    },
    security: {
      messages: {
        matchError:
          "Please make sure you entered your first and last name with a space in between.",
        accountDeleted: "Your account has been deleted.",
      },
      password: {
        title: "Change Password",
        socialMessage:
          "It looks like you are using social login. Therefore, you are not able to change your password.",
        resetMessage:
          "Please submit your password reset request to get an email from us. Please follow the directions that we have sent you to reset your password.",
      },
      accountDelete: {
        title: "Delete Account",
        message:
          "We are very sorry to see you go. If you can tell us why you are leaving us, we will do our best to improve our service.",
        radioTitle: "What is the reason for leaving?",
        inputLabel: "Approve",
        confirmationDescription:
          "Please type your 'First Name' and 'Last Name' with a space in between. Then click 'Approve' button to delete your account permanently.",
        confirmationNote:
          "Please note that this process is irreversible and your all date will be erased permanently including your requests, reviews, and messages.",
        button: "Delete Account",
      },
    },
    requests: {
      title: "Your Requests",
      noRequests: "You have no requests.",
      generalRequests: "General Requests",
      new: "New",
      date: "Date",
      type: "Type",
      company: "Company",
      product: "Product",
      message: "Message",
      actionsTitle: "Actions",
    },
    bookmarks: {
      title: "Your Bookmarks",
      searchPlaceHolder: "Search in your bookmarks.",
      noSearchResults: "No results found.",
      noBookmarks: "You have no bookmarks.",
      titles: {
        posts: "Posts",
      },
    },
  },
  // Listing Pages
  listingPages: {
    filterContainerTitle: "FILTERS",
    filterButton: "Filter by",
    showMoreButton: "Show More",
    foundProducts: "Found Products:",
    foundCompanies: "Found Companies:",
    foundTradeShows: "Found Shows:",
    searchPlaceHolders: {
      company: "Search companies...",
      product: "Search products...",
      tradeShow: "Search trrade shows...",
    },
    noResultMessage: "There is no result for your search.",
    noResultButton: "Clear All Filters",
    pageHero: {
      company: {
        title: "Agricultural Companies",
        description:
          "Find the company you are looking for based on the product you need.",
        type: "Companies",
      },
      product: {
        title: "Agricultural Products from Turkey",
        description:
          "Find the product that you need, easy and fast. Contact with the company in a second and grow your business fast!",
        type: "Products",
      },
      tradeShow: {
        title: "Agricultural Trade Shows 2022",
        description:
          "Find domestic and international agricultural trade shows and get the latest information about them.",
        type: "Shows",
      },
      about: {
        title: "What is hortiturkey?",
        description:
          "Find hundreds of agricultural products from Turkey and grow your business fast!",
        type: "hortiturkey",
      },
      contactUs: {
        title: "Contact Us",
        description:
          "Is there anything you would like to share or suggest? Contact us now and let's see what we can do together.",
        type: "Contact",
      },
    },
    mobileFilterButtons: {
      company: "See Companies",
      product: "See Products",
      tradeShow: "See Trade Shows",
    },
  },
  // Global Search
  globalSearch: {
    types: {
      all: "All",
      company: "Companies",
      product: "Products",
      trade_show: "Trade Shows",
      post: "Posts",
    },
    placeholder: "Search for companies, products, trade shows, and more...",
    noHistoryMessage: "Find everything you need in one place!",
    searchHistory: "Previous Searches",
    results: "Results",
  },
  // Home INT Page
  homeINT: {
    hero: {
      title1: "Find",
      title2: "the best agricultural products",
      title3: "in one place.",
      what: "What is",
      paragraph1:
        "Are you looking for new products or new companies to establish business?",
      paragraph2:
        "Find everything you need in one place to grow your agriculture business.",
      callout1: "1000+ Products",
      callout2: "Exclusive Companies",
      callout3: "Multi-Language Support",
      callout4: "Live Chat with Agent",
    },
    featuredProducts: {
      title: "Featured Products",
    },
    featuredCompanies: {
      title: "Featured Companies",
    },
    footer: {
      title1: "Contact",
      title2: "trusted agricultural companies",
      title3: "in your language.",
      chatButton: "START LIVE CHAT",
      boxTitle: "WE SPEAK YOUR LANGUAGE",
      boxDescription:
        "Our agents can speak Russian, Arabic, English, Spanish, Persian and much more.",
    },
    boxes: {
      getHelp: {
        title: "GET HELP",
        description: "Do you need consultation on agriculture in Turkey?",
        button: "Start Live Chat",
      },
      products: {
        title: "PRODUCTS",
        description:
          "Discover 1.000+ agricultural products and filter what you need.",
        button: "SEE PRODUCTS",
      },
      companies: {
        title: "COMPANIES",
        description: "Find exclusive companies to establish new partnerships.",
        button: "SEE COMPANIES",
      },
      events: {
        title: "EVENTS",
        description:
          "Find best events suitable for your needs and get information.",
        button: "SEE EVENTS",
      },
    },
  },
  // Contact Us Page
  contactUs: {
    Office: "Office",
    Contact: "Contact",
    Company: "Company",
    Founders: "Founders",
    Address: "Address",
    Email: "E-mail",
    Phone: "Phone",
    CompanyName: "Company Name",
    TaxID: "Tax ID",
  },

  // Event Page
  event: {
    title: "EVENT",
    button: {
      register: "Signup Free",
      registered: "Already Registered",
    },
    platforms: "Where to watch?",
    titles: {
      eventContent: "Event Content",
      speakers: "Speakers",
    },
    footer: {
      poweredBy: "Powered by hortiturkey",
    },
    share: {
      linkCopied: "Link copied to clipboard",
      title: "Share the Event!",
      copy: "Copy Link",
    },
    status: {
      live: "LIVE",
      ended: "STREAMING ENDED",
    },
    chatbox: {
      joinText:
        "You may join the chat to ask questions to the speakers and meet other attendees.",
      joinButton: "Join Chat",
      emptyText: "No messages yet.",
    },
    live: {
      startingText: "The event will start in a few minutes.",
      startingTextJoin: "Don't forget join chat while waiting.",
      endText: "The event has ended. Thank you for your participation.",
    },
  },

  // Featured TradeShow
  featuredTradeShow: {
    badge: "Featured Trade Show",
    button1: "Learn More",
    button2: "Register Free",
  },
};

export default en;
